<template>
    <div class="contact-media" v-if="socialMedia">
        <template v-for="item in arrayIremix" :key="item.id">
            <a :href=item.link  :aria-label=item.ariaLabel target="_blank">
                <div class="icon-back">
                    <i :class=item.icon></i>
                </div>
            </a>
        </template> 
    </div>

    <div class="contact-name" v-else>
        <div class="icon-back">
            <i :class=iRemix></i>
        </div>
        <p>
            <a class="no-decoration" :href="formattedLink">
                {{text}}
            </a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ButtonContact',
    props: {
        text: {
            type: String,
        },
        iRemix: {
            type: String,
        },
        socialMedia: {
            type: Boolean,
            default: false,
        },
        arrayIremix: {
            type: Array,
        },
        link: {
            type: String,
            required: true, 
        },
    },
    computed: {
        formattedLink() {
            if (!this.link) {
                return '#';
            }
            if (this.link.includes('@')) {
                return `mailto:${this.link}`;
            }
            return `tel:${this.link}`;
        }
    }
}
</script>

<style scoped>
.no-decoration{
    color: var(--dark-violet)!important;
}
.contact-name, .contact-media{
    margin-bottom: 1rem;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--white);
    border-radius: 0.5rem;
    width: 100%;
    transition: all 0.5s ease-in-out;
    will-change: transform;
    cursor: pointer;
}
.contact-name p, .contact-media p{
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.4rem;
    font-weight: 600;
    padding: 0 0.5rem 0 0.6rem; 
}
.contact-name:hover, .contact-media:hover{
    background-color: var(--light-violet);
    border: 1px solid var(--light-violet);
    transform: scale(1.02);
}
.icon-back{
    background-color: var(--soft-violet);
    min-width: 40px;
    min-height: 40px;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
i{
    font-size: 1.8rem;
}
.contact-media .icon-back{
    border-radius: 100%;
    margin-right: 1rem;
}
a{
    text-decoration: none;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .icon-back{
        min-width: 50px;
        min-height: 50px;
    }
    .contact-name p, .contact-media p {
        width: 100%;
        font-size: 1.3rem;
        line-height: 1.4rem;
        padding: 0 0.5rem 0 1rem; 
    }
}
</style>