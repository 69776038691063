/* IMAGES */
/* import CellPng from '../../assets/img/photos/png/cell.png';
import CellJpg from '../../assets/img/photos/jpg/cell.jpg';
import CellWebp from '../../assets/img/photos/webp/cell.webp'; */

import Cell2Webp from '../../assets/img/blog/webp/cell2.webp';
import Cell2Webp320 from '../../assets/img/blog/webp/cell2_320.webp';
import Cell2Webp480 from '../../assets/img/blog/webp/cell2_480.webp';
import Cell2Webp768 from '../../assets/img/blog/webp/cell2_768.webp';
import Cell2Webp1080 from '../../assets/img/blog/webp/cell2_1080.webp';

import CellsWebp from '../../assets/img/blog/webp/cells.webp';
import CellsWebp320 from '../../assets/img/blog/webp/cells_320.webp';
import CellsWebp480 from '../../assets/img/blog/webp/cells_480.webp';
import CellsWebp768 from '../../assets/img/blog/webp/cells_768.webp';
import CellsWebp1080 from '../../assets/img/blog/webp/cells_1080.webp';

/*import KneeWebp from '../../assets/img/blog/webp/knee.webp';
import KneeWebp320 from '../../assets/img/blog/webp/knee_320.webp';
import KneeWebp480 from '../../assets/img/blog/webp/knee_480.webp';
import KneeWebp768 from '../../assets/img/blog/webp/knee_768.webp';
import KneeWebp1080 from '../../assets/img/blog/webp/knee_1080.webp';
*/
import CellWebp from '../../assets/img/blog/webp/cell.webp';
import CellWebp320 from '../../assets/img/blog/webp/cell_320.webp';
import CellWebp480 from '../../assets/img/blog/webp/cell_480.webp';
import CellWebp768 from '../../assets/img/blog/webp/cell_768.webp';
import CellWebp1080 from '../../assets/img/blog/webp/cell_1080.webp';

import plasma from '../../assets/img/blog/webp/plasma.webp';
import plasma320 from '../../assets/img/blog/webp/plasma_320.webp';
import plasma480 from '../../assets/img/blog/webp/plasma_480.webp';
import plasma768 from '../../assets/img/blog/webp/plasma_768.webp';
import plasma1080 from '../../assets/img/blog/webp/plasma_1080.webp';

import hyaluronic from '../../assets/img/blog/webp/hyaluronic.webp';
import hyaluronic320 from '../../assets/img/blog/webp/hyaluronic_320.webp';
import hyaluronic480 from '../../assets/img/blog/webp/hyaluronic_480.webp';
import hyaluronic768 from '../../assets/img/blog/webp/hyaluronic_768.webp';
import hyaluronic1080 from '../../assets/img/blog/webp/hyaluronic_1080.webp';

import chondropathy from '../../assets/img/blog/webp/chondropathy.webp';
import chondropathy320 from '../../assets/img/blog/webp/chondropathy_320.webp';
import chondropathy480 from '../../assets/img/blog/webp/chondropathy_480.webp';
import chondropathy768 from '../../assets/img/blog/webp/chondropathy_768.webp';
import chondropathy1080 from '../../assets/img/blog/webp/chondropathy_1080.webp';

/* BLOG INFO */

let blog = [
    {
        id: 'que_son_las_celulas_madre',
        classSection: "stem-cells",
        section: "Células madre", 
        title: "¿Qué son las células madre y para qué sirven?",
        subtitle: "Las células madre mesenquimales son un tipo de células madre adultas que se encuentran en varios tejidos del cuerpo, como la médula ósea, el tejido adiposo y el tejido conjuntivo. Estas células tienen la capacidad de diferenciarse en varios tipos de células del cuerpo, como osteoblastos, adipocitos y miocitos.",
        description: "Las células madre son células que tienen la capacidad de dividirse y dar lugar a diferentes tipos de células especializadas.",
        srcWebp: Cell2Webp,
        srcWebp320: Cell2Webp320,
        srcWebp480: Cell2Webp480,
        srcWebp768: Cell2Webp768,
        srcWebp1080: Cell2Webp1080,
        altImg: "Células madres", 
        date: '01 de abril de 2024',
        author: 'Clínica Novocel',
        fullText: `
            Las <span class="bolder">células madre mesenquimales</span> son células que se encuentran en nuestro cuerpo durante toda nuestra vida y que se caracterizan por dos propiedades fundamentales: su capacidad de auto renovación, es decir, de dividirse para formar una copia de sí misma, y su potencial de diferenciación, es decir, de poder convertirse en distintos tipos de células de nuestro organismo. 

            <div class="salto"></div>

            Su función es conservar el equilibrio fisiológico, manteniendo la estructura y función de los tejidos. Las utilizamos a diario para reemplazar células o tejidos que se han dañado o desgastado, actuando como un sistema reparador. Sin embargo, conforme envejecemos, va disminuyendo su calidad y cantidad, motivo por el cual, resulta beneficioso administrar células de alto potencial. 

            <div class="salto"></div>

            El <span class="bolder">tratamiento combinado para lesiones de cartílago</span> implementado por Clínica Novocel incluye 2 elementos fundamentales: células madre mesenquimales derivadas de tejido de cordón umbilical (células con el más alto potencial clínico, con propiedades anti-inflamatorias, inmunomoduladoras y regenerativas) y ácido hialurónico compatible biológicamente, producto que no sólo actúa como amortiguador y lubricante de la articulación, sino que también favorece la sobrevida y actividad de las células madre. 

            <div class="salto"></div>

            El objetivo del tratamiento combinado de células madre y ácido hialurónico es que el paciente disminuya los signos artrósicos, aumente su funcionalidad articular y recupere movilidad. Se estimula la capacidad regenerativa del organismo para revertir los mecanismos patológicos que conllevan al desgaste de cartílago, logrando disminuir el dolor y la inflamación, al mismo tiempo que se propicia la regeneración de cartílago articular, manteniendo los beneficios clínicos por un amplio periodo de tiempo.
        `
    },
    {
        id: 'que_pasa_despues_de_una_infiltracion',
        classSection: "stem-cells",
        section: "Infiltraciones", 
        title: "¿Qué pasa después de la infiltración?",
        subtitle: "Muchos pacientes tienen dudas acerca de qué pasa despúes del procedimiento. Esto es lo que puedes esperar después de un procedimiento de infiltración intrarticular con células madre.",
        description: "Después de la infiltración con células madre viene la recuperación y rehabilitación. Conoce aquí lo que ocurre luego del procedimiento.",
        srcWebp: CellWebp,
        srcWebp320: CellWebp320,
        srcWebp480: CellWebp480,
        srcWebp768: CellWebp768,
        srcWebp1080: CellWebp1080,
        altImg: "Rodilla en recuperación", 
        date: '27 de agosto de 2024',
        author: 'Clínica Novocel',
        fullText: `
            Hasta la fecha, en Clínica Novocel, hemos tratado a más de 550 pacientes con la terapia traumatológica de infiltración intrarticular de células madre y ácido hialurónico, como terapia combinada de medicina regenerativa. A todos nuestros pacientes les entregamos, desde nuestra consulta científica, los conceptos biológicos del procedimiento, les explicamos los principios y qué esperar después del procedimiento. 

            <div class="salto"></div>

            Sin embargo, la pregunta sigue presente... <span class="bolder">¿Qué esperar despúes del procedimiento con células madre?</span>

            <div class="salto"></div>

            Despúes de la infiltración que realiza nuestro traumatólogo en nuestra sala de procedimiento autorizada, llega el momento de volver a casa. Sabrás con anticipación que debes evitar hacer fuerza y movimientos bruscos, por lo que seguramente estarás acompañado por alguien cercano a quien posiblemente le debas un café por haberte esperado :) No te preocupes... nosotros ya le habremos ofrecido un café, así que no debiera cobrarte nada. 

            <div class="salto"></div>

            Te irás a casa y te entregaremos una compresa fría para que apliques en la zona de la infiltración. Probablemente sientas un poco de dolor debido a que hemos agregado en tu articulación un volumen que antes no existía, así que sentirás cierta inflamación en la zona intervenida. Para tratar esta inflamación, el traumatólogo te entregará una orden médica para que puedas adquirir analgésicos que te ayudarán en caso de dolor. Es super importante suspender los medicamentos que te indiquen suspender, y respetar las dosis indicadas. 

            <div class="salto"></div>

            Luego, vienen 2 días de recuperación. Durante estos 2 días debes evitar hacer movimientos bruscos, y sobre todo seguir la recomendación médica que recibirás, como no consumir ciertos medicamentos y empezar tu terapia kinesiológica. Puedes sentir dolor, o puede ser que no sientas dolor. En cualquier caso, siempre tendrás los medicamentos de apoyo y el teléfono directo de nuestra directora científica quien estará disponible por cualquier duda. Recuerda no hacer movimientos bruscos por 2 días, esto incluye: evitar subir y bajar escaleras, no trotar ni correr, y no bailar. Aunque si suena un rock & roll deberás apagar la música lo más pronto posible para evitar que tu cuerpo se apodere de ti y se ponga a bailar solo =) 

            <div class="salto"></div>

            Ya entrarás a la etapa de recuperación después de los primeros días. La zona intervenida comenzará a desinflamarse y podrás retomar tus actividades. Es muy importante iniciar tus terapias kinesiológicas lo más pronto posible para rehabilitarte debidamente. Recuerda que en Novocel puedes tomar tus sesiones de kinesiología. 

            <div class="salto"></div>

            Recibirás un llamado de nuestra directora científica para saber cómo ha evolucionado todo dentro de las primeras semanas. 

            <div class="salto"></div>

            Transcurrido 1 mes después de la intervención, será el momento de tu control. Si todo va bien, nuestro equipo médico te dará el alta médica. Podrás ayudarnos con un testimonio el día de tu control así podamos ayudar juntos para que otros pacientes conozcan estas terapias. Seguiremos en contacto contigo en los meses sucesivos para registrar que todo vaya bien. 

            <div class="salto"></div>

            <span class="bolder">¿Qué pasa entonces después del procedimiento con células madre?</span> Súper fácil: Aplicar compresas de frío, consumir los medicamentos indicados solo en caso de dolor o inflamación, evitar ciertos medicamentos que te hayamos indicado según tu caso, realizar reposo los 2 primeros días, iniciar tu proceso de rehabilitación, y asistir a tu control para evaluar tu evolución. 

            <div class="salto"></div>

            ¡Nos vemos en Clínica Novocel!
        `
    },
    {
        id: 'procedimiento_con_celulas_madre',
        classSection: "stem-cells",
        section: "Células madre", 
        title: "¿En qué consiste la infiltración con células madre?",
        subtitle: "Infiltración con células madre, un procedimiento ambulatorio, seguro y eficaz para el tratamiento de la artrosis",
        description: "Las células madre mesenquimales son células que tenemos en nuestro cuerpo y que con el paso de los años las perdemos en cantidad y calidad, razón por la cual resulta útil administrar un tratamiento con células de alto potencial.",
        srcWebp: CellWebp,
        srcWebp320: CellWebp320,
        srcWebp480: CellWebp480,
        srcWebp768: CellWebp768,
        srcWebp1080: CellWebp1080,
        altImg: "Rodilla en recuperación", 
        date: '01 de diciembre de 2024',
        author: 'Clínica Novocel',
        fullText: `
            En Clínica Novocel trabajamos con células derivadas de tejido de cordón umbilical de un “donante universal” por tener beneficios biológicos superiores. Estas células tienen la capacidad de multiplicarse, transformarse en distintos tipos de tejidos, y secretar sustancias reparadoras. El tratamiento con células madre es ambulatorio y consiste en una única infiltración (inyección) intraarticular o directamente en la zona de lesión (tendones o ligamentos), lo que permite disminuir el dolor y la inflamación, deteniendo el progreso de la enfermedad y aportando un potencial regenerativo para reparar el daño del tejido.

            <div class="salto"></div>

            El procedimiento dura alrededor de 45 minutos total y considera: la firma del consentimiento informado, preparación para infiltración, infiltración, y recomendaciones post-operatorias. Cuando es requerido la infiltración se apoya con guía ecográfica.

            <div class="salto"></div>

            La terapia celular con células madre es una alternativa médica de última generación que está revolucionando el tratamiento de las lesiones traumatológicas, ya que ha demostrado ser segura y efectiva en patologías no responsivas.

            <div class="salto"></div>

            Si las opciones de tratamientos convencionales como la kinesiología, el consumo de antiinflamatorios o infiltraciones con corticoides, o plasma rico en plaquetas no te han proporcionado un alivio significativo, es posible que un tratamiento con células madre te pueda ayudar.

            <div class="salto"></div>

            Ten en cuenta que, para lograr un buen resultado con un tratamiento de terapia celular, es importante que tu evaluación clínica esté en manos de un médico profesional ¡Agenda tu evaluación con nosotros!
        `
    },
    {
        id: 'historia_de_las_celulas_madre',
        classSection: "stem-cells",
        section: "Células madre", 
        title: "La historia de las Células Madre",
        subtitle: "Los hitos científicos más relevantes desde 1970 asociados a las células madre",
        description: "El estudio de las células madre ha sido un viaje de descubrimientos que ha cambiado la medicina moderna. Desde la identificación de las células madre mesenquimales (MSCs) en los años 70, estas células han mostrado un enorme potencial en la regeneración de tejidos y el tratamiento de enfermedades como la artrosis.",
        srcWebp: CellsWebp,
        srcWebp320: CellsWebp320,
        srcWebp480: CellsWebp480,
        srcWebp768: CellsWebp768,
        srcWebp1080: CellsWebp1080,
        altImg: "Células madres", 
        date: '03 de enero de 2025',
        author: 'Clínica Novocel',
        fullText: `La historia de las células madre y su aplicación en medicina regenerativa es un recorrido fascinante que ha revolucionado el tratamiento de diversas enfermedades. Desde la década de 1970, los avances en el estudio de las células madre mesenquimales (MSCs) han dado lugar a tratamientos innovadores que buscan restaurar tejidos y mejorar la calidad de vida. 

                <div class="salto"></div>

                Acompáñanos en este viaje fascinante a través de los hitos y descubrimientos que han llevado a la medicina a un nivel de innovación sin precedentes, y descubre cómo el futuro de la salud ya está aquí.

                <div class="salto"></div>
                <div class="salto"></div>

                <h3>Principales Hitos de las Células Madre Mesenquimales (MSCs)</h3>

                <div class="salto"></div>

                <h4>1976 - Descubrimiento de las MSCs: </h4>
                En 1976, el científico soviético Alexander Friedenstein realizó un descubrimiento revolucionario al identificar en la médula ósea un tipo especial de células con capacidades regenerativas únicas, conocidas hoy como células madre mesenquimales (MSCs). Friedenstein observó que estas células, además de tener la capacidad de dividirse y formar colonias (es decir, eran clonogénicas), podían diferenciarse en diversos tipos de tejidos como hueso, cartílago, grasa y músculo.<br/>
                Este hallazgo fue un gran avance, ya que en ese momento, la investigación en células madre estaba en sus etapas iniciales, y las posibilidades de utilizar células para reparar y regenerar tejidos dañados apenas comenzaban a vislumbrarse. Las MSCs demostraron que la médula ósea no solo contenía células hematopoyéticas (que generan células sanguíneas), sino también células madre con un potencial regenerativo más amplio.<br/>
                Este descubrimiento sentó las bases para el desarrollo de la medicina regenerativa y abrió las puertas a estudios posteriores que exploraron cómo las MSCs podían aplicarse en la reparación de tejidos dañados. Desde entonces, el trabajo de Friedenstein ha inspirado décadas de investigación y ha permitido la evolución de terapias basadas en MSCs para tratar enfermedades degenerativas, como la artrosis, demostrando el poder y la versatilidad de estas células en la medicina moderna.<br/>
                
                <div class="mt-1">
                    <span class="bold">Referencia</span>: Friedenstein AJ, Chailakhjan RK, Lalykina KS. 
                    <span class="italic">"The development of fibroblast colonies in monolayer cultures of guinea-pig bone marrow and spleen cells."</span>
                    Cell and Tissue Kinetics, 1970. 
                </div>
                <div class="salto"></div>
                
                <h4>1991 - Término "Célula Madre Mesenquimal":</h4>
                En 1991, el investigador estadounidense Arnold Caplan hizo una contribución clave al acuñar el término “célula madre mesenquimal” (MSC), que transformó la forma en que la comunidad científica entendía y clasificaba estas células. Caplan observó que las células derivadas de la médula ósea poseían un potencial extraordinario para diferenciarse en varios tipos de tejidos, incluyendo hueso, cartílago, grasa y músculo. Esta capacidad de transformarse en diferentes linajes celulares las definía como células multipotentes.<br/>
                Al proponer el término “célula madre mesenquimal,” Caplan no solo describía su capacidad de diferenciación, sino también su papel potencial en medicina regenerativa. Con su visión innovadora, sugirió que estas células podían utilizarse para regenerar tejidos y reparar daños en el cuerpo, una idea pionera para la época que amplió considerablemente las aplicaciones clínicas de las MSCs.<br/>
                El trabajo de Caplan fue fundamental para catalizar la investigación y el desarrollo de terapias regenerativas, ya que proporcionó una definición formal y un marco conceptual para estudiar estas células. Desde entonces, las MSCs han sido el foco de miles de estudios y se han convertido en una herramienta prometedora para tratar una variedad de enfermedades degenerativas, como la artrosis y otras condiciones ortopédicas.<br/>
                <div class="mt-1">
                    <span class="bold">Referencia</span>: Caplan AI. 
                    <span class="italic">"Mesenchymal stem cells."</span>
                    Journal of Orthopaedic Research, 1991.
                </div>

                <div class="salto"></div>

                <h4>2000 - Estudios en Artrosis Animal:</h4>
                En el año 2000, los estudios en animales marcaron un avance decisivo en la investigación sobre células madre mesenquimales (MSCs) al demostrar que estas células podían reparar el cartílago dañado en modelos animales con artrosis. Investigadores como Murphy y su equipo realizaron experimentos en los que inyectaron MSCs en articulaciones afectadas, observando una mejora significativa en la estructura del cartílago y una reducción en los síntomas asociados a la artrosis.<br/>
                Estos experimentos mostraron que las MSCs no solo tenían el potencial de diferenciarse en células de cartílago, sino también de modular la inflamación en las articulaciones, un factor clave en la progresión de la artrosis. Al reducir la inflamación y favorecer la regeneración del cartílago, estas células demostraron ser una alternativa innovadora a los tratamientos convencionales, que solo alivian los síntomas sin detener el daño.<br/>
                Estos primeros estudios en animales abrieron el camino para explorar el uso de MSCs en el tratamiento de la artrosis en humanos. Desde entonces, la terapia con MSCs ha ganado interés como una estrategia para restaurar la función articular en personas con artrosis, ofreciendo una solución que no solo trata los síntomas, sino que también aborda las causas subyacentes del desgaste en el cartílago. Este enfoque innovador ha inspirado décadas de investigación en medicina regenerativa y ha sentado las bases para los ensayos clínicos en humanos que vendrían años después
                <div class="mt-1">
                <span class="bold">Referencia</span>: Murphy JM et al. <span class="italic">"Stem cell therapy in a caprine model of osteoarthritis."</span> Arthritis & Rheumatism, 2003.
                </div>

                <div class="salto"></div>

                <h4>2006 - Definición de MSCs por la ISCT:</h4>
                La Sociedad Internacional para la Terapia Celular establece criterios mínimos para definir las MSCs, como marcadores específicos y capacidad de diferenciación.
                <div class="mt-1">
                <span class="bold">Referencia</span>: Dominici M et al. <span class="italic">"Minimal criteria for defining multipotent mesenchymal stromal cells. The International Society for Cellular Therapy position statement."</span> Cytotherapy, 2006.
                </div>

                <div class="salto"></div>

                <h4>2010 - Ensayos Clínicos en Humanos:</h4>
                En 2010, comenzaron los primeros ensayos clínicos en humanos utilizando células madre mesenquimales (MSCs) para tratar la artrosis de rodilla, marcando un hito en la medicina regenerativa. Estos estudios pioneros evaluaron la seguridad y eficacia de las MSCs autólogas (células madre extraídas del propio paciente) al ser implantadas en las articulaciones dañadas por la artrosis.<br/>
                Los resultados fueron prometedores: los pacientes experimentaron una mejora en la movilidad articular y una reducción del dolor en comparación con los tratamientos convencionales. Además, las imágenes mostraron indicios de regeneración del cartílago y reducción de la inflamación, sugiriendo que las MSCs no solo ayudaban a aliviar los síntomas, sino que también podían reparar y restaurar el tejido dañado.                
                <div class="mt-1">
                <span class="bold">Referencia</span>: 
                    Centeno CJ et al. 
                    <span class="italic">"Increased knee cartilage volume in degenerative joint disease using percutaneously implanted, autologous mesenchymal stem cells."</span> 
                    Pain Physician, 2008.
                </div>

                <div class="salto"></div>

                <h4>2016 - Aprobación en Japón:</h4>
                En 2016, Japón se convirtió en uno de los primeros países en aprobar oficialmente terapias basadas en células madre mesenquimales (MSCs) para el tratamiento de enfermedades articulares, incluyendo la artrosis. Esta aprobación fue un paso importante no solo para Japón, sino para el campo de la medicina regenerativa a nivel mundial, ya que estableció un marco regulatorio específico que permitía el uso de estas terapias en entornos clínicos de manera controlada y segura.<br/>
                El gobierno japonés, conocido por su enfoque progresivo en el campo de la biotecnología y la medicina regenerativa, impulsó regulaciones que permitieron a las clínicas aplicar terapias con MSCs, siempre que estas fueran seguras y ofrecieran beneficios potenciales para los pacientes. Con esta aprobación, Japón dio prioridad a los pacientes con enfermedades articulares, ofreciendo una opción innovadora para quienes buscaban tratamientos más efectivos y menos invasivos que la cirugía o los medicamentos convencionales.<br/>
                Este hito fue pionero en abrir camino para la aceptación de las MSCs en el ámbito clínico y alentó a otros países a considerar la regulación de terapias regenerativas. Desde entonces, Japón ha continuado liderando la adopción de terapias basadas en células madre, posicionándose como un referente en el uso de tratamientos avanzados que ofrecen una nueva esperanza para los pacientes con artrosis y otras condiciones degenerativas.
                <div class="mt-1">
                    <span class="bold">Referencia</span>: Sipp D. 
                    <span class="italic">"Conditional approval: Japan lowers the bar for regenerative medicine products."</span> Cell Stem Cell, 2015.
                </div>
                <div class="salto"></div>

                <h4>2018-2020 - Avances en Terapias Personalizadas:</h4>
                En 2020, la medicina regenerativa avanzó significativamente con el desarrollo de nuevas técnicas de criopreservación y expansión de células madre mesenquimales (MSCs). Estas innovaciones permitieron mejorar tanto la eficacia como la accesibilidad de los tratamientos basados en MSCs, abriendo las puertas a terapias más personalizadas y adaptadas a las necesidades de cada paciente.
                <div class="salto"></div>

                <span class="bolder">- Criopreservación:</span> Esta técnica permite almacenar las MSCs a muy bajas temperaturas durante largos períodos sin que pierdan su capacidad regenerativa. Gracias a la criopreservación, los pacientes pueden disponer de sus propias células en el momento que lo necesiten, sin necesidad de repetir el proceso de extracción. Esto facilita tratamientos continuos y reduce los tiempos de espera, permitiendo que las terapias con células madre se integren mejor en los planes de cuidado a largo plazo.
                <div class="salto"></div>
                <span class="bolder">- Expansión celular:</span> La expansión permite multiplicar las MSCs en el laboratorio, obteniendo una cantidad mayor de células a partir de una muestra inicial. Esto es esencial en terapias personalizadas, ya que asegura que el paciente reciba la cantidad de MSCs necesaria para lograr una regeneración eficaz en el tejido afectado. Además, este proceso permite ajustar las dosis y personalizar los tratamientos según la gravedad de la enfermedad o la condición específica del paciente..
                <div class="salto"></div>

                <div class="mt-1">
                <span class="bold">Referencia</span>: Galipeau J, Sensébé L. <span class="italic">"Mesenchymal Stromal Cells: Clinical Challenges and Therapeutic Opportunities."</span> Cell Stem Cell, 2018.
                </div>
                <div class="salto"></div>                
                
                <hr/>
                <div class="salto"></div>    

                La historia de las Células Madre tiene Premios Nobel también. ¡Nada menor! <br/>
                Si bien los premios Nobel en esta área no se centran directamente en las MSCs, han sido fundamentales para el desarrollo del campo de células madre:

                <div class="salto"></div>

                <h4>1990 - Joseph Murray y Donnall Thomas:</h4>
                Reciben el Nobel por avances en trasplantes celulares, estableciendo una base para terapias con células madre.
                <div class="mt-1">
                <span class="bold">Referencia</span>: Nobel Prize in Physiology or Medicine 1990.

                <div class="salto"></div>
                
                <h4>2007 - Mario Capecchi, Martin Evans y Oliver Smithies:</h4>
                Reciben el Nobel por sus trabajos en células madre embrionarias de ratón, impulsando la investigación genética en células madre.
                <div class="mt-1">
                <span class="bold">Referencia</span>: Nobel Prize in Physiology or Medicine 2007.

                <div class="salto"></div>
                
                <h4>2012 - John Gurdon y Shinya Yamanaka:</h4>
                Descubren cómo reprogramar células adultas en células pluripotentes inducidas (iPSCs), revolucionando la medicina regenerativa.
                <div class="mt-1">
                <span class="bold">Referencia</span>: Nobel Prize in Physiology or Medicine 2012.

                <div class="salto"></div>
                <hr/><br/>
                En Clínica Novocel, aplicamos los avances más recientes en terapias con MSCs para condiciones como la artrosis, ofreciendo tratamientos innovadores y personalizados que buscan restaurar la salud y mejorar la calidad de vida de nuestros pacientes. Contáctanos para conocer más sobre cómo nuestras terapias pueden ayudarte a vivir con menos dolor y mayor movilidad.<br/>
                Ya sabes, si alguna vez te preguntan de dónde provienen las células madre que te infiltraste en Clínica Novocel, puedes partir diciendo: "En 1970 ..." `
    }, 
    {
        id: 'plasma_rico_en_plaquetas',
        classSection: "stem-cells",
        section: "Plasma rico en plaquetas", 
        title: "Plasma rico en plaquetas",
        subtitle: "Qué es el plasma rico en plaquetas y para qué sirve",
        description: "El plasma rico en plaquetas o PRP, una técnica ambulatoria para el tratamiento de lesiones musculoesqueléticas que aprovecha el poder regenerativo de tu propio cuerpo. En qué consiste el tratamiento, qué es el PRPR, y cómo funciona, son preguntas que podrás resolver aquí.",
        srcWebp: plasma,
        srcWebp320: plasma320,
        srcWebp480: plasma480,
        srcWebp768: plasma768,
        srcWebp1080: plasma1080,
        altImg: "Rodilla en recuperación", 
        date: '15 de enero de 2025',
        author: 'Clínica Novocel',
        fullText: `
            Si creías que la única forma de aliviar el dolor de la artrosis era resignarte o pensar en cirugía, ¡te tenemos una buena noticia! Existe un tratamiento natural y efectivo que aprovecha el poder regenerativo de tu propio cuerpo: el Plasma Rico en Plaquetas (PRP).

            <div class="salto"></div>
            <br/>
            <h4>¿Qué es el PRP y por qué debería interesarme?</h4>

            El Plasma Rico en Plaquetas es un concentrado de plaquetas obtenido de tu propia sangre. Sí, de tu propia sangre (pero tranquilo, no tienes que beberla ni nada raro). Estas plaquetas están cargadas con factores de crecimiento, unas moléculas que ayudan a regenerar tejidos dañados y reducir la inflamación.

            <div class="salto"></div>

            Básicamente, si la artrosis te está haciendo la vida difícil o una lesión no te deja moverte bien, el PRP le dice a tu cuerpo:
            "Oye, manos a la obra, tenemos que reparar esto."

            <div class="salto"></div>

            <br/>
            <h4>¿Cómo es el procedimiento?</h4>

            1.  Te sacamos un poco de sangre, como en un examen de rutina. <br/>
            2.  La centrifugamos para separar las plaquetas y concentrarlas en el plasma.<br/>
            3.  Te infiltramos el PRP en la zona afectada, con una aguja delgada y mucho cuidado.<br/>

            <div class="salto"></div>

            El proceso dura unos 30 minutos, es ambulatorio y, lo mejor, no necesitas anestesia ni hospitalización. Saldrás caminando por tu cuenta (y con una buena excusa para darte un premio después).

            <div class="salto"></div>
            <br/>
            <h4>¿Cuáles son los beneficios?</h4>

            ✅ <span class="bold">Regenera tejidos:</span> Ideal para cartílago, tendones y músculos. <br/>
            ✅ <span class="bold">Reduce el dolor y la inflamación:</span> Adiós a los analgésicos constantes.<br/>
            ✅ <span class="bold">Evita o retrasa la cirugía:</span> Si podemos reparar el daño antes, mejor.<br/>
            ✅ <span class="bold">Es un tratamiento natural:</span> Sin químicos, sin medicamentos agresivos.<br/>

            <div class="salto"></div>
            <br/><h4>¿Para quién es ideal el PRP?</h4>         

            Para personas con artrosis, lesiones en articulaciones, tendinitis o dolores musculares que no mejoran con otros tratamientos. En pocas palabras, si moverte te hace sentir como una puerta vieja y oxidada, el PRP puede ser la bisagra que estabas buscando.

            <div class="salto"></div>

            Si te interesa saber más o quieres agendar una cita, en Clínica Novocel estamos listos para ayudarte. Llama y consulta con nuestros especialistas. Tu cuerpo tiene el poder de sanar, solo necesita un pequeño empujón. 🚀

        `
    },
    {
        id: 'acido_hialuronico',
        classSection: "stem-cells",
        section: "Ácido hialurónico", 
        title: "Ácido hialurónico para artrosis: Alivio sin cirugía",
        subtitle: "Qué es el ácido hialurónico y para qué sirve",
        description: "El ácido hialurónico es una sustancia natural que se encuentra en el cuerpo humano. Es un componente del líquido sinovial de las articulaciones, clave para su biomecánica, y que en los pacientes con artrosis, su concentración y peso molecular se encuentran disminuidos",
        srcWebp: hyaluronic,
        srcWebp320: hyaluronic320,
        srcWebp480: hyaluronic480,
        srcWebp768: hyaluronic768,
        srcWebp1080: hyaluronic1080,
        altImg: "Rodilla en recuperación", 
        date: '02 de febrero de 2025',
        author: 'Clínica Novocel',
        fullText: `
                La viscosuplementación es ... <br/>
                Para, para, para ¿la visco qué? 😬 <br/>
                Calma, no es un trabalenguas, es el nombre técnico para la infiltración intraarticular de ácido hialurónico, un tratamiento diseñado para aliviar el dolor articular y mejorar la movilidad en personas con artrosis.

                <div class="salto"></div>

                El ácido hialurónico es un lubricante natural que actúa como un amortiguador dentro de las articulaciones, reduciendo la inflamación y el dolor. En términos simples, ayuda a que los huesos dejen de "rozarse" tanto, lo que disminuye la fricción y mejora la absorción de impactos.

                <br/><div class="salto"></div>

                <br/><h4>¿Para qué casos se recomienda?</h4>
                🔹 Artrosis de rodilla, cadera y otras articulaciones afectadas por el desgaste del cartílago.<br/>
                🔹 Dolor articular persistente que no mejora con otros tratamientos.<br/>
                🔹 Personas que buscan alternativas antes de considerar una cirugía.<br/>

                <br/><div class="salto"></div>

                <h4>¿Cómo es el procedimiento?</h4>
                ✅ Ambulatorio y rápido: Se realiza en consulta con anestesia local y dura entre 15 y 30 minutos.<br/>
                ✅ Infiltración directa en la articulación: Se inyecta ácido hialurónico en la zona afectada, restaurando la lubricación.<br/>
                ✅ Sin hospitalización: Puedes retomar tus actividades cotidianas casi de inmediato.<br/>

                <br/><div class="salto"></div>

                <h4>¿Quiénes no deben realizarse este tratamiento?</h4>
                ❌ Personas con infecciones activas en la zona a tratar.<br/>
                ❌ Pacientes con alergia al ácido hialurónico.<br/>

                <br/><div class="salto"></div>

                <h4>¿Listo para moverte sin dolor?</h4>                
                No dejes que la artrosis limite tu vida. Agenda tu evaluación en Novocel y da el primer paso hacia una mayor movilidad y bienestar. 🚶‍♂️✨
        `
    },
    {
        id: 'infiltracion_con_corticoides',
        classSection: "regenerative-medicine",
        section: "Corticoides", 
        title: "Alivio rápido para el dolor articular, pero con precaución",
        subtitle: "Para qué sirve el corticoides y qué consideraciones tener",
        description: "Las infiltraciones con corticoides alivian el dolor y la inflamación articular rápidamente, pero su uso excesivo puede dañar el cartílago. En Novocel, te aayudamos a que tengas todas la información a la mano.",
        srcWebp: chondropathy,
        srcWebp320: chondropathy320,
        srcWebp480: chondropathy480,
        srcWebp768: chondropathy768,
        srcWebp1080: chondropathy1080,
        altImg: "Corticoides", 
        date: '12 de febrero de 2025',
        author: 'Clínica Novocel',
        fullText: `
                ¿Dolor intenso en las articulaciones? A veces, cuando el malestar es insoportable, se necesita una solución efectiva y rápida. Aquí es donde entran en juego las infiltraciones con corticoides, un tratamiento que ayuda a reducir la inflamación y el dolor articular casi de inmediato.

                <div class="salto"></div>


                Pero ojo 👀, la infiltración con corticoides no son una solución mágica ni debe usarse a la ligera. Si bien alivian el dolor con rapidez, su uso repetido puede afectar la salud del cartílago generando mayor daño a largo plazo. 

                <div class="salto"></div>


                <br/><h4>¿Cómo funcionan los corticoides en la articulación?</h4>

                Los corticoides intraarticulares son potentes antiinflamatorios que reducen la hinchazón y el dolor en casos de artrosis, artritis y otras afecciones articulares. Actúan bloqueando los procesos inflamatorios en la articulación, permitiendo que el paciente recupere su movilidad y alivie la rigidez, sin embargo, por un tiempo acotado.

                <div class="salto"></div>

                <br/><h4>¿Para qué casos están recomendados?</h4>
                🔹 Artrosis avanzada con dolor intenso. <br/>
                🔹 Inflamaciones articulares crónicas o agudas. <br/>
                🔹 Pacientes que necesitan alivio rápido antes de iniciar otro tratamiento de largo plazo. <br/>

                <br/><div class="salto"></div>


                <h4>¿Cómo es el procedimiento?</h4>
                ✅ Rápido y ambulatorio: Se realiza en consulta con anestesia local en menos de 30 minutos. <br/>
                ✅ Efecto casi inmediato: Muchos pacientes sienten alivio en 24 a 48 horas. <br/>
                ✅ Aplicación localizada: Se inyecta directamente en la articulación afectada para un efecto más potente y dirigido. <br/>

                <br/><div class="salto"></div>


                <h4>¿Por qué no abusar de los corticoides?</h4>
                🔴 No regeneran el cartílago, solo alivian el dolor y la inflamación. <br/>
                🔴 Uso repetido puede debilitar la articulación y acelerar el desgaste. <br/>
                🔴 Deben administrarse con control médico, evitando más de 3 o 4 infiltraciones al año en la misma articulación. <br/>

                <br/><div class="salto"></div>


                <h4>¿Entonces, corticoides sí o no?</h4>
                Sí, pero con responsabilidad. Son una excelente opción para quienes necesitan un alivio inmediato, pero deben combinarse con otros tratamientos que realmente ayuden a proteger y mejorar la articulación a largo plazo.
                <div class="salto"></div>
                Si el dolor te limita, agenda tu evaluación en Novocel y recibe la mejor orientación médica. ¡No dejes que la inflamación te detenga! 🚶‍♂️💪

        `
    }, 
]

export default blog;