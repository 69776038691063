<template>
    <router-link :to="to" class="no-decoration" :aria-label="name">
        <div class="container" :style="backgroundStyle" ref="container" :data-src="imgLink" >
            <div class="text">
                <div>
                    <p class="name">{{ name }}</p>
                    <p class="profession">{{ profession }}</p>
                </div>
                <div>
                    <p class="description">{{ description }}</p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "ImageTextCard",
    props: {
        imgLink: {
            type: String,
        },
        name: {
            type: String,
        },
        profession: {
            type: String,
        },
        description: {
            type: String,
        },
        to: {
            type: String,
        }
    },
    data() {
        return {
            isVisible: false
        };
    },
    computed: {
        backgroundStyle() {
        return {
            backgroundImage: this.isVisible ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(247, 247, 247, 0.7), rgba(247, 247, 247, 1)), url(${this.imgLink})` : '',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        };
        }
    },
    mounted() {
        this.checkVisibility();
        window.addEventListener('scroll', this.checkVisibility);
    },
    methods: {
        checkVisibility() {
        const containerRect = this.$refs.container.getBoundingClientRect();
        const isVisible = containerRect.top < window.innerHeight;
        if (isVisible) {
            this.isVisible = true;
            window.removeEventListener('scroll', this.checkVisibility);
        }
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.checkVisibility);
    }
};
</script>

<style scoped>
.no-decoration{
    text-decoration: none;
}
.container{
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 16px;
    height: 420px;
    max-height: 420px;
    justify-content: flex-end;
}
img{
    border-radius: 16px;
    max-height: 420px;
}
.text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.name, .profession, .description{
    color: var(--dark-violet);
}
.name{
    font-size: 28px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 6px;
}
.profession{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
}
.description{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
}
#team .swiper-slide:not(.swiper-slide-active) .image-text-card .text{
    display: none;
}
@media screen and (min-width: 800px) {
    .name{
        font-size: 38px;
        line-height: 40px;
        margin-bottom: 16px;
    }
    .profession{
        font-size: 20px;
        line-height: 24px;
    }
    .description{
        font-size: 16px;
        line-height: 20px;
    }
}
@media screen and (min-width: 1600px) {
    .mySwiper div.container{
        min-height: 550px;
        margin-bottom: 5px;
    }
}
</style>