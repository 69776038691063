<template>
    <section id="treatments">
        <SectionName text="Especialidades" class="d-flex justify-content-center" />
        <TitleSecodary class="text-center mt-4" ppalText="Especialidades médicas"/>
        <DecorationCurveDouble class="decoration-curve" v-if="decorationCurve" />
        <div :class="{'cards-treatments': decorationCurve}">
            <div class="cards-treatments-container" :class="{'no-decoration': !decorationCurve}">
                <CardTreatments class="card-item" 
                :background=background
                title="Traumatología"
                to="/traumatologia">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Diagnóstico médico y exámenes</li>
                            <li>Infiltraciones de medicina regenerativa</li>
                            <li>Cirugía</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                title="Geriatría"
                to="/geriatria">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Evaluación funcional</li>
                            <li>Evaluación cognitiva</li>
                            <li>Evaluación emocional</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                title="Reumatología"
                to="/reumatologia">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Evaluación médica</li>
                            <li>Infiltraciones</li>
                            <li>Terapias biológicas</li>
                        </ul>
                    </template>
                </CardTreatments>
            </div>

            <div class="cards-treatments-container">
                <CardTreatments class="card-item" 
                :background=background
                title="Medicina Regenerativa"
                to="/medicina_regenerativa">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Infiltración con células madre</li>
                            <li>Infiltración con plasma rico en plaquetas</li>
                            <li>Infiltración con ácido hialurónico</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                title="Medicina Deportiva"
                to="/medicina_deportiva">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Evaluación ecográfica</li>
                            <li>Evaluación médica</li>
                            <li>Infiltraciones</li>
                        </ul>
                    </template>
                </CardTreatments>
            </div>

            <div class="cards-treatments-container" :class="{'no-decoration': !decorationCurve}">
                <CardTreatments class="card-item" 
                :background=background
                title="Medicina Estética"
                to="/medicina_estetica">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Toxina botulínica </li>
                            <li>Ácido hialurónico</li>
                            <li>Cirugía</li>
                        </ul>
                    </template>
                </CardTreatments>                
                <CardTreatments class="card-item" 
                :background=background
                title="Medicina del Dolor"
                to="/medicina_del_dolor">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Bloqueos nerviosos</li>
                            <li>Radiofrecuencia</li>
                            <li>Infiltraciones</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                title="Medicina General"
                to="/medicina_general">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Seguimiento de pacientes</li>
                            <li>Control post-infiltraciones</li>
                            <li>Procedimientos endovenosos</li>
                        </ul>
                    </template>
                </CardTreatments>
            </div>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import DecorationCurveDouble from '@/components/DecorationCurveDouble.vue';
import CardTreatments from '@/components/CardTreatments.vue';
import Cell from '@/assets/img/icons/webp/celula.webp';
import Health from '@/assets/img/icons/webp/salud.webp';
import Syringe from '@/assets/img/icons/webp/jeringa.webp';
import Blood from '@/assets/img/icons/webp/sangre.webp';


export default {
    name: 'SectionTreatments',
    components: {
        SectionName,
        TitleSecodary,
        DecorationCurveDouble,
        CardTreatments,
    },
    props: {
        decorationCurve: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
        }
    },
    data() {
        return {
            Cell : Cell,
            Health : Health,
            Syringe : Syringe,
            Blood : Blood,
        }
    },
}
</script>

<style scoped>
#treatments{
    padding-top: 3rem;
}
.decoration-curve{
    position: relative;
    top: -310px;
    display: flex;
    justify-content: center;
}
.cards-treatments{
    max-width: 1400px;
    margin: auto;
    margin-top: -200px;
}
ul.card-description{
    list-style-type: "- ";
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.5rem;
    padding-left: 0.9rem;
    margin-bottom: 0;
}
.cards-treatments-container{
    margin-bottom: 1rem;
    min-width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;    
    align-items: center;
    justify-content: center;
}
.cards-treatments-container:first-of-type{
    margin-top: 3rem;
}
.card-item{
    flex: 1 0 100% ;
}
.no-decoration{
    margin-top: 20px;
}
/* RESPONSIVE */
@media (min-width:375px) {
    .cards-treatments{
        margin-top: -250px;
    }
}
@media (min-width:425px) {
    .cards-treatments{
        margin-top: -280px;
    }
}
@media (min-width:550px) {
    .cards-treatments{
        margin-top: -330px;
    }
}
@media (min-width:650px) {
    .cards-treatments{
        margin-top: -400px;
    }
}
@media (min-width:768px) {
    .cards-treatments{
        margin-top: -350px;
    }
}
@media (min-width:992px) {
    .card-item{
        flex: 0 0 calc(50% - 10px);
    }
    .cards-treatments{
        margin-top: -400px;
    }
    .cards-treatments-container:first-of-type{
        margin-top: 1rem;
    }
}
@media (min-width:1070px) {
    .cards-treatments{
        margin-top: -450px;
    }
}
@media (min-width:1200px) {
    .card-item{
        flex: 0 0 calc(33.33% - 14px);
    }
    .cards-treatments-container:last-of-type{
        justify-content: flex-end;
    }
    .cards-treatments{
        margin-top: -500px;
    }
}
@media (min-width:1300px) {
    .cards-treatments{
        margin-top: -550px;
    }
}
@media (min-width:1450px) {
    .cards-treatments{
        margin-top: -610px;
    }
}
</style>