import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/css/novocel.css'
import { createApp } from 'vue'

/*---------  PARA USAR EN HOME -----------*/
import router from "@/router/index.js";  
import App from './App.vue'; 

/*----------  PARA USAR EN LANDING ------------*/
//  import router from "@/router/indexLanding.js"; 
// import App from './LandingApp.vue';  

/*----------  PARA USAR EN LANDING HUMBERTO ------------*/
/*import router from "@/router/indexHbto.js"; 
import App from './LandingHbto.vue';  */

/*----------  PARA USAR EN LANDING REUMA ------------*/
/*import router from "@/router/indexReuma.js"; 
import App from './LandingReuma.vue';  */

/*----------  PARA USAR EN LANDING DOLOR ------------*/
/*import router from "@/router/indexDolor.js"; 
import App from './LandingDolor.vue';   */

const app = createApp(App);
app.use(router);
app.mount('#app');
export { app };