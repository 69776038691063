<template>
    <header>
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <router-link to="/" class="navbar-brand" aria-label="Home Clínica Novocel">
                    <picture>
                        <source srcset="../../assets/img/logos/svg/logo-horizontal-color.svg" type="image/svg+xml" />
                        <source srcset="../../assets/img/logos/web/logo-horizontal-color.webp" type="image/webp">
                        <source srcset="../../assets/img/logos/png/logo-horizontal-color.png" type="image/png">
                        <img class="logo" src="../../assets/img/logos/svg/logo-horizontal-color.svg" alt="Logo Novocel - Medicina regenerativa" />
                    </picture>
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="sections collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link" aria-label="Inicio">Inicio</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <p class="nav-link dropdown-toggle mb-0" aria-label="Especialidades" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Especialidades
                                <i class="ri-arrow-down-s-line"></i>
                            </p>
                            <ul class="dropdown-menu">
                                <li><router-link to="/traumatologia" class="dropdown-item" aria-label="Traumatología">Traumatología</router-link></li>
                                <li><router-link to="/traumatologia_oncologica" class="dropdown-item" aria-label="Traumatología Oncológica">Traumatología Oncológica</router-link></li>
                                <!-- <li><router-link to="/medicina_regenerativa" class="dropdown-item" aria-label="Medicina Regenerativa">Medicina Regenerativa</router-link></li> -->
                                <li><router-link to="/medicina_del_dolor" class="dropdown-item" aria-label="Medicina del Dolor">Medicina del Dolor</router-link></li>                           
                                <li><router-link to="/reumatologia" class="dropdown-item" aria-label="Reumatología">Reumatología</router-link></li>
                                <li><router-link to="/geriatria" class="dropdown-item" aria-label="Geriatría">Geriatría</router-link></li>
                                <li><router-link to="/medicina_deportiva" class="dropdown-item" aria-label="Medicina Deportiva">Medicina Deportiva</router-link></li>
                                <li><router-link to="/medicina_estetica" class="dropdown-item" aria-label="Medicina Estética">Medicina Estética</router-link></li>
                                <li><router-link to="/medicina_general" class="dropdown-item" aria-label="Medicina General">Medicina General</router-link></li>
                                <!-- <li><router-link to="/homeopatia" class="dropdown-item" aria-label="Homeopatía">Homeopatía</router-link></li> -->
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link to="/nosotros" class="nav-link" aria-label="Nosotros">Nosotros</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/valores" class="nav-link" aria-label="Valores">Valores</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <p class="nav-link dropdown-toggle mb-0" aria-label="Recursos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Aprende más
                                <i class="ri-arrow-down-s-line"></i>
                            </p>
                            <ul class="dropdown-menu">
                                <li><router-link to="/preguntas" class="dropdown-item" aria-label="Preguntas">Preguntas</router-link></li>
                                <li><router-link to="/blog" class="dropdown-item" aria-label="Blog">Blog</router-link></li>
                            </ul>
                        </li>                        
                        <li class="nav-item">
                            <router-link to="/contacto" class="nav-link" aria-label="Contacto">Contacto</router-link>
                        </li>
                    </ul>
                </div>
                <div class="button-action-container">
                    <ButtonAction link="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" text="Agenda aquí" :targetBlank=true class="mx-2"/>
                    <ButtonAction link="tel:+56930580356" text="+56930580356" :targetBlank=true />
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue';

export default {
    name: 'NavBar',
    components: {
        ButtonAction,
    }
}
</script>

<style scoped>
header{
    position: relative;
    z-index: 10;
}
.navbar {
    background-color: var(--white);
    height: 64px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
}
.button-action-container{
    min-width: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sections .navbar-nav{
    margin: auto;
    width: auto;
}
.navbar-nav .nav-item{
    padding: 0 0.5rem;
}
.navbar-nav .nav-item:hover{
    background-color: var(--soft-hover-violet);
    border-radius: 0.5rem;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus{
    background-color: var(--soft-hover-violet);
    color: var(--dark-violet);
}
ul.dropdown-menu{
    border: none;
    border-radius: 0.6rem;
    top: 58px;
    margin-bottom: 1rem;
}
.navbar-nav  a{
    font-weight: 500;
}
.dropdown-toggle::after {
    border: 0;
}
.nav-link.dropdown-toggle{
    position: relative;
    bottom: 5px;
}
.ri-arrow-down-s-line{
    font-size: 20px;
    position: relative;
    top: 2.5px;
}
.nav-link.active{
    font-weight: 700;
}
.button-action-container{
    display: none!important;
}
button.navbar-toggler, button.navbar-toggler:focus-visible, button:focus:not(:focus-visible){
    border: none;
    box-shadow: none;
}
div#navbarSupportedContent{
    position: relative;
    top: 24px;
    z-index: 10;
    min-width: calc(100vw - 62px);
    left: -28px;
    border-radius: 1rem;
    padding: 6px 16px;
    background-color: var(--white);
}
img.logo{
    height: 32px;
}
/* RESPONSIVE */
@media (min-width:500px){
    div#navbarSupportedContent{
        min-width: calc(100vw - 69px);
    }
}

@media (min-width:768px) {
    div#navbarSupportedContent{
        min-width: calc(100vw - 90px); /* 89px */
    }
}

@media (min-width:992px) {
    div#navbarSupportedContent{
        position: relative;
        top: 0px;
        min-width: auto;
        left:0px;
        box-shadow: none;
        padding: 0;
    }
}

@media (min-width:1080px) {
    .button-action-container{
        display: flex!important;
    }
}
</style>
