import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/HomePage/HomePage.vue';
/* const Home = () => import('@/views/HomePage/HomePage.vue'); */
const TraumatologyPage = () => import('@/views/TraumatologyPage/TraumatologyPage.vue');
const TraumatologyOncologicPage = () => import('@/views/TraumatologyOncologic/TraumatologyOncologic.vue');
const RegenerativePage = () => import('@/views/RegenerativePage/RegenerativePage.vue');
const GeriatryPage = () => import('@/views/GeriatryPage/GeriatryPage.vue');
const ReumatologyPage = () => import('@/views/ReumatologyPage/ReumatologyPage.vue');
const SportPage = () => import('@/views/SportPage/SportPage.vue');
const MedicinePage = () => import('@/views/MedicinePage/MedicinePage.vue');
const EsteticaPage = () => import('@/views/EsteticaPage/EsteticaPage.vue');
const DolorPage = () => import('@/views/DolorPage/DolorPage.vue');
const TreatmentsPage = () => import('@/views/TreatmentsPage/TreatmentsPage.vue');
const PathologyPage = () => import('@/views/PathologyPage/PathologyPage.vue');
const FAQsPage = () => import('@/views/FAQsPage/FAQsPage.vue');
const BlogPage = () => import('@/views/BlogPage/BlogPage.vue');
const AboutUsPage = () => import('@/views/AboutUsPage/AboutUsPage.vue');
const ContactPage = () => import('@/views/ContactPage/ContactPage.vue');
const BlogPost = () => import('@/views/BlogPage/BlogPost.vue');
const PricesPage = () => import('@/views/PricesPage/PricesPage.vue');
const LandingPage = () => import('@/views/LandingPage/LandingPage.vue');
const HomeopathPage = () => import('@/views/HomeopathPage/HomeopathPage.vue');

const routes = [
    { path: '/', component: Home, meta: { title: 'Inicio - Clínica Novocel' } },
    { path: '/traumatologia', component: TraumatologyPage, meta: { title: 'Traumatología - Clínica Novocel' } },
    { path: '/traumatologia_oncologica', component: TraumatologyOncologicPage, meta: { title: 'Traumatología Oncológica - Clínica Novocel' } },
    { path: '/medicina_regenerativa', component: RegenerativePage, meta: { title: 'Medicina Regenerativa - Clínica Novocel' } },
    { path: '/geriatria', component: GeriatryPage, meta: { title: 'Geriatría - Clínica Novocel' } },
    { path: '/reumatologia', component: ReumatologyPage, meta: { title: 'Reumatología - Clínica Novocel' } },
    { path: '/medicina_deportiva', component: SportPage, meta: { title: 'Medicina Deportiva - Clínica Novocel' } },
    { path: '/medicina_general', component: MedicinePage, meta: { title: 'Medicina General - Clínica Novocel' } },
    { path: '/medicina_del_dolor', component: DolorPage, meta: { title: 'Medicina del Dolor - Clínica Novocel' } },
    { path: '/medicina_estetica', component: EsteticaPage, meta: { title: 'Medicina Estética - Clínica Novocel' } },
    { path: '/homeopatia', component: HomeopathPage, meta: { title: 'Homeopatía - Clínica Novocel' } },
    { path: '/tratamientos', component: TreatmentsPage, meta: { title: 'Tratamientos - Clínica Novocel' } },
    { path: '/patologias', component: PathologyPage, meta: { title: 'Patologías - Clínica Novocel' } },
    { path: '/preguntas', component: FAQsPage, meta: { title: 'Preguntas Frecuentes - Clínica Novocel' } },
    { path: '/blog', component: BlogPage, meta: { title: 'Blog - Clínica Novocel' } },
    { path: '/blog/post/:id', component: BlogPost, meta: { title: 'Artículo del Blog - Clínica Novocel' } },
    { path: '/nosotros', component: AboutUsPage, meta: { title: 'Sobre Nosotros - Clínica Novocel' } },
    { path: '/contacto', component: ContactPage, meta: { title: 'Contacto - Clínica Novocel' } },
    { path: '/valores', component: PricesPage, meta: { title: 'Valores y Precios - Clínica Novocel' } },
    { path: '/landing', component: LandingPage, meta: { title: 'Landing - Clínica Novocel' } }
];

const router = createRouter({
    /* history: createWebHistory(), */
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Clínica Novocel';
    window.scrollTo(0, 0);
    next();
});

export default router;