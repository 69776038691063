<template>
    <div class="action-button" :class="backColor" v-if="!small">
        <a v-if="targetBlank" :href="link" :target="targetBlank ? '_blank' : '_self'">
            {{text}}
        </a>
        <router-link v-else :to=link :aria-label=title>
            {{text}}
        </router-link>
    </div>
    <div class="action-button-small" :class="backColor" v-else>
        <router-link  :to=link :aria-label=title>
            <p>{{text}}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'ButtonAction',
    props:  {
        text: {
            type: String,
        },
        link: {
            type: String,
        },
        targetBlank: {
            type: Boolean,
            default: false,
        },
        backColor: {
            type: String,
            default: "violet",
        },
        small: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style scoped>
.action-button {
    border-radius: 42px;
    height: 42px;
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    will-change: transform;
    cursor: pointer;
}
.action-button a {
    text-decoration: none;
}
.action-button:hover{
    transform: scale(1.04);
}
.action-button.violet{
    background-color: var(--dark-violet);
}
.action-button.violet:hover{
    background-color: var(--light-violet);
}
.action-button.violet a {
    color: var(--white)!important;
}
.action-button.white{
    background-color: var(--white);
}
.action-button.white a {
    color: var(--dark-violet)!important;
}
.action-button.white:hover{
    background-color: var(--light-violet);
}
.action-button.none{
    background-color: none;
    border: 1px solid var(--dark-violet);
}
.action-button.none a{
    color: var(--dark-violet);
}

.action-button-small{
    margin-top: 1rem;
}
.action-button-small p{
    border: 1px solid var(--dark-violet);
    border-radius: 42px;
    width: fit-content;
    margin: 0;
    padding: 0.4rem 1.4rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}
.action-button-small p:hover {
    background-color: var(--dark-violet);
    color: var(--white);
}
.action-button-small a{
    text-decoration: none!important;
}
</style>
