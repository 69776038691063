<template>
    <section id="pathologies">
        <div class="titles">
            <SectionName text="Infiltraciones" class="d-flex justify-content-center" />
            <TitleSecodary class="text-center mt-4" ppalText="Infiltraciones con Células Madre"/>
        </div>
        <div class="group-path">
            <CardPathologiesIntro 
            class="pathologie pathologie-presentation"
            pathologie="Medicina regenerativa"
            descriptionTitle="Tratamientos regenerativos para lesiones musculoesqueléticas y degenerativas"
            descriptionText="Los procedimientos traumatológicos que entregamos a nuestros pacientes, sustentados científicamente, atienden a un conjunto de patologías como:"
            to="/patologias"
            />
            <CardPathologies 
            class="pathologie"
            pathologie="Células Madre"
            number="95%"
            descriptionNumber="Efectividad"
            descriptionText="El tratamiento combinado con células madre y ácido hialurónico en artrosis de rodilla ha reportado una efectividad del 95%."
            to="/patologias"
            />
            <CardPathologies 
            class="pathologie"
            pathologie="Experiencia"
            number="450"
            descriptionNumber="Pacientes"
            descriptionText="Experiencia en 450 pacientes a quienes realizamos seguimiento de hasta 12 meses para evaluar la efectividad y seguridad de los procedimientos."
            to="/patologias"
            />
        </div>
    </section>
</template>

<script>
import CardPathologies from '@/components/CardPathologies.vue';
import CardPathologiesIntro from '@/components/CardPathologiesIntro.vue';
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';

export default {
    name: 'SectionPathologies',
    components: {
        CardPathologies,
        CardPathologiesIntro,
        SectionName,
        TitleSecodary,
    },
}
</script>

<style scoped>
#pathologies{
    margin-top: 4.5rem;
}
#pathologies .group-path{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 1400px;
    margin: auto;
}
#pathologies .group-path:last-of-type{
    margin-top: 20px;
    /* flex-direction: row-reverse; */
}
.pathologie{
    flex: 1 0 100% ;
}
.pathologie-presentation{
    hyphens: auto;
    word-wrap: break-word;
}
.titles{
    margin-bottom: 3rem;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .pathologie{
        flex: 0 0 calc(50% - 10px);
    }
    .pathologie.pathologie-presentation{
        flex: 1 0 100%;
    }
    .pathologie-presentation{
        hyphens: none;
        word-wrap: unset;
    }
}
@media (min-width:1200px) {
    .pathologie{
        flex: 1 1 calc(25% - 15px);
    }
    .pathologie.pathologie-presentation{
        flex: 1 1 calc(50% - 10px);
    }
}
</style>